// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_a766060","type-body-2":"type-body-2_f224252","type-body-3":"type-body-3_e88c7e4","type-body-4":"type-body-4_ccbd22c","type-section-title":"type-section-title_fc6f9a9","type-notes":"type-notes_d9dc5b7","type-item-title":"type-item-title_e6640d0","type-item-subtitle":"type-item-subtitle_c7d98e8","type-subtitle":"type-subtitle_ef1f45b","type-title":"type-title_b0ffe62","row":"row_c0aa108","listItem":"listItem_ec97548","listItemInner":"listItemInner_acb5193","listItemCost":"listItemCost_c721f0e","editButton":"editButton_a75f66d","lighterText":"lighterText_b219640","listItemHeader":"listItemHeader_d883a80","tooltipGlyph":"tooltipGlyph_aaa4b5c","linkButton":"linkButton_ac50c97","listItemValues":"listItemValues_da1d1ba","listItemValue":"listItemValue_b9fa8c6","valueName":"valueName_ab9e6cb","valueAmount":"valueAmount_c2a97fc","valueList":"valueList_f2da4f5","valueItem":"valueItem_c7aaf3f","listButton":"listButton_a59a914","emptyValuePlaceholder":"emptyValuePlaceholder_b2cc3d9"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_a766060\",\"type-body-2\":\"type-body-2_f224252\",\"type-body-3\":\"type-body-3_e88c7e4\",\"type-body-4\":\"type-body-4_ccbd22c\",\"type-section-title\":\"type-section-title_fc6f9a9\",\"type-notes\":\"type-notes_d9dc5b7\",\"type-item-title\":\"type-item-title_e6640d0\",\"type-item-subtitle\":\"type-item-subtitle_c7d98e8\",\"type-subtitle\":\"type-subtitle_ef1f45b\",\"type-title\":\"type-title_b0ffe62\",\"row\":\"row_c0aa108\",\"listItem\":\"listItem_ec97548\",\"listItemInner\":\"listItemInner_acb5193\",\"listItemCost\":\"listItemCost_c721f0e\",\"editButton\":\"editButton_a75f66d\",\"lighterText\":\"lighterText_b219640\",\"listItemHeader\":\"listItemHeader_d883a80\",\"tooltipGlyph\":\"tooltipGlyph_aaa4b5c\",\"linkButton\":\"linkButton_ac50c97\",\"listItemValues\":\"listItemValues_da1d1ba\",\"listItemValue\":\"listItemValue_b9fa8c6\",\"valueName\":\"valueName_ab9e6cb\",\"valueAmount\":\"valueAmount_c2a97fc\",\"valueList\":\"valueList_f2da4f5\",\"valueItem\":\"valueItem_c7aaf3f\",\"listButton\":\"listButton_a59a914\",\"emptyValuePlaceholder\":\"emptyValuePlaceholder_b2cc3d9\"}";
        // 1737747134524
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  