// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","modalCustom":"modalCustom_d887e45","instruction":"instruction_a4892ec","inputContainer":"inputContainer_e9efa70","switchContainer":"switchContainer_ae40415","inputSwitch":"inputSwitch_bdf8c4f","vinOverlayForm":"vinOverlayForm_eb8ac6f","label":"label_f27b77d","marginCustom":"marginCustom_a9a7002","line":"line_b344cab","companies":"companies_fd44d25","radioButtons":"radioButtons_a38d360","input":"input_d29de18","footer":"footer_ace1677","button":"button_b922276","backButton":"backButton_e870c05","arrowSprite":"arrowSprite_c04e83f","errorMessageContainer":"errorMessageContainer_c4fd0fe","spinnerStyle":"spinnerStyle_af37b6f","link":"link_a1e4bd0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"modalCustom\":\"modalCustom_d887e45\",\"instruction\":\"instruction_a4892ec\",\"inputContainer\":\"inputContainer_e9efa70\",\"switchContainer\":\"switchContainer_ae40415\",\"inputSwitch\":\"inputSwitch_bdf8c4f\",\"vinOverlayForm\":\"vinOverlayForm_eb8ac6f\",\"label\":\"label_f27b77d\",\"marginCustom\":\"marginCustom_a9a7002\",\"line\":\"line_b344cab\",\"companies\":\"companies_fd44d25\",\"radioButtons\":\"radioButtons_a38d360\",\"input\":\"input_d29de18\",\"footer\":\"footer_ace1677\",\"button\":\"button_b922276\",\"backButton\":\"backButton_e870c05\",\"arrowSprite\":\"arrowSprite_c04e83f\",\"errorMessageContainer\":\"errorMessageContainer_c4fd0fe\",\"spinnerStyle\":\"spinnerStyle_af37b6f\",\"link\":\"link_a1e4bd0\"}";
        // 1737747124851
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  