// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","button":"button_f7ee591","input":"input_de32779","primaryAuthButton":"primaryAuthButton_e9357d5","secondaryAuthButton":"secondaryAuthButton_c5726ef","errors":"errors_a8d29cd","authForm":"authForm_a8d415e","header":"header_d82aa21","subHeader":"subHeader_fb2965a","altLink":"altLink_d59954c","successText":"successText_d356d9d","textBody":"textBody_e6e205b","inputGroup":"inputGroup_d42cf5c","inputSection":"inputSection_b1cd0a4","passwordInputContainer":"passwordInputContainer_c85df81","showHidePass":"showHidePass_e923bef","showHideGlyph":"showHideGlyph_a9309c2","loadingSpinner":"loadingSpinner_bb6c58e","successIcon":"successIcon_ef26f7b","altLinks":"altLinks_f3fd4a6","poweredBy":"poweredBy_b901b08","title":"title_e8e32d3","supportText":"supportText_b968c34","panel":"panel_c6be6f7","label":"label_fe1535d","subLabel":"subLabel_fabfbba","panelInput":"panelInput_b54767b","panelRadio":"panelRadio_f5ab152","codeInput":"codeInput_f4fe8a4","linkButton":"linkButton_a6556fe","backButton":"backButton_ea89a6d"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"button\":\"button_f7ee591\",\"input\":\"input_de32779\",\"primaryAuthButton\":\"primaryAuthButton_e9357d5\",\"secondaryAuthButton\":\"secondaryAuthButton_c5726ef\",\"errors\":\"errors_a8d29cd\",\"authForm\":\"authForm_a8d415e\",\"header\":\"header_d82aa21\",\"subHeader\":\"subHeader_fb2965a\",\"altLink\":\"altLink_d59954c\",\"successText\":\"successText_d356d9d\",\"textBody\":\"textBody_e6e205b\",\"inputGroup\":\"inputGroup_d42cf5c\",\"inputSection\":\"inputSection_b1cd0a4\",\"passwordInputContainer\":\"passwordInputContainer_c85df81\",\"showHidePass\":\"showHidePass_e923bef\",\"showHideGlyph\":\"showHideGlyph_a9309c2\",\"loadingSpinner\":\"loadingSpinner_bb6c58e\",\"successIcon\":\"successIcon_ef26f7b\",\"altLinks\":\"altLinks_f3fd4a6\",\"poweredBy\":\"poweredBy_b901b08\",\"title\":\"title_e8e32d3\",\"supportText\":\"supportText_b968c34\",\"panel\":\"panel_c6be6f7\",\"label\":\"label_fe1535d\",\"subLabel\":\"subLabel_fabfbba\",\"panelInput\":\"panelInput_b54767b\",\"panelRadio\":\"panelRadio_f5ab152\",\"codeInput\":\"codeInput_f4fe8a4\",\"linkButton\":\"linkButton_a6556fe\",\"backButton\":\"backButton_ea89a6d\"}";
        // 1737747117149
        var cssReload = require("../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  